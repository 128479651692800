import React, { useState } from 'react';
import './GridBackground.css';

const GridBackground = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index, event) => {
    // Set a quick transition when mouse enters
    event.target.style.transitionDuration = '0.6s';
    setHoveredIndex(index);
  };

  const handleMouseLeave = (index, event) => {
    // Set a random transition when mouse leaves
    const randomDuration = `${Math.random() * (5 - 1) + 1}s`;
    event.target.style.transitionDuration = randomDuration;
    setHoveredIndex(null);
  };

  return (
    <div className="grid-container">
      {Array.from({ length: 10000 }, (_, index) => (
        <div
          key={index}
          className={`grid-cell ${
            index === hoveredIndex ? 'hovered' : ''
          }`}
          onMouseEnter={(e) => handleMouseEnter(index, e)}
          onMouseLeave={(e) => handleMouseLeave(index, e)}
        ></div>
      ))}
    </div>
  );
};

export default GridBackground;
